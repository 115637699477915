<template>
  <div class="style-search">
    <div>
      <ai-breadcrumb />
      <div class="new-button">
        <guide class="guide-button" />
      </div>
    </div>
    <div class="toolbar">
      <el-form class="search-form" :inline="true" size="small">
        <el-form-item class="search-form-gutter" :label="$t('analysis.platform')">
          <el-select style="width: 88px" v-model="currentPlatform" @change="changePlatform" filterable>
            <el-option v-for="item in platformList" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('common.brand')">
          <el-select style="width: 128px" v-model="currentBrandId" filterable clearable>
            <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('common.keyword')">
          <el-input v-model="keyword" />
        </el-form-item>
        <el-form-item class="search-form-gutter">
          <el-button size="small" :circle="true" type="primary" @click="fetchData" icon="el-icon-search"></el-button>
        </el-form-item>
      </el-form>
      <div class="download-btn">
        <el-button :disabled="true" size="small" class="excel-dl-btn" icon="el-icon-download" @click="downloadAll">{{
          $t("analysis.downloadAll") }}</el-button>
      </div>
    </div>
    <div class="data-content" v-if="item">
      <el-row>
        <el-col v-loading="dataLoading" :span="6" class="image-block">
          <el-image class="image-block-content" :src="item.imageTop" :preview-src-list="[item.imageTop]" fit="contain" />
        </el-col>
        <el-col v-loading="dataLoading" class="data-block" :span="6">
          <div class="data-block-content">
            <el-row class="content-line">
              <span class="content-label">{{
                $t("productInfo.baseDate")
              }}</span>
              {{ item.linkDate }}
            </el-row>
            <el-row class="content-line">
              <span class="content-label">{{ $t("productInfo.logo") }}</span>
              <img style="min-height: 72px; max-height: 72px" v-if="item.brand.iconImageUrl"
                :src="item.brand.iconImageUrl | imageThumbnailUrl(false)" class="avatar" />
            </el-row>
            <el-row class="content-line">
              <span class="content-label">{{ $t("common.item") }}</span>
              {{ item.t2 }}
            </el-row>
            <el-row class="content-line">
              <span class="content-label">{{
                $t("productInfo.yearSeason")
              }}</span>
              {{ item.onSaleTime }}
            </el-row>
            <el-row class="content-line">
              <span class="content-label">{{ $t("productInfo.color") }}</span>
              {{ cutText(item.colors, ", ") }}
              <el-popover placement="right" trigger="click">
                <el-table :show-header="false" :stripe="true" :data="item.colors.map((t) => {
                  return { color: t };
                })
                  " size="mini">
                  <el-table-column width="150" label="" prop="color"></el-table-column>
                </el-table>
                <el-button :disabled="!item.colors" slot="reference" type="primary" size="mini">more</el-button>
              </el-popover>
            </el-row>
            <el-row class="content-line">
              <span class="content-label">{{ $t("productInfo.size") }}</span>
              {{ cutText(item.sizes, ", ") }}
              <el-popover v-if="item.sizes" placement="right" trigger="click">
                <el-table :show-header="false" :stripe="true" :data="item.sizes.map((t) => {
                  return { size: t };
                })
                  " size="mini">
                  <el-table-column width="150" label="" prop="size"></el-table-column>
                </el-table>
                <el-button :disabled="!item.sizes" slot="reference" type="primary" size="mini">more</el-button>
              </el-popover>
            </el-row>
            <el-row class="content-line">
              <span class="content-label">{{ $t("productInfo.textile") }}</span>
              {{ item.material }}
            </el-row>
          </div>
        </el-col>
        <el-col v-loading="dataLoading" class="data-block" :span="6">
          <div class="data-block-content">
            <el-row class="content-line">
              <span class="content-label">{{
                $t("productInfo.patternFG")
              }}</span>
              {{ item.pattern }}
            </el-row>
            <el-row class="content-line">
              <span class="content-label">{{ $t("productInfo.collar") }}</span>
              {{ item.collarType }}
            </el-row>
            <el-row class="content-line">
              <span class="content-label">{{
                $t("productInfo.waistStyle")
              }}</span>
              {{ item.waistType }}
            </el-row>
            <el-row class="content-line">
              <span class="content-label">{{
                $t("productInfo.pantsLength")
              }}</span>
              {{ item.pantLength }}
            </el-row>
            <el-row class="content-line">
              <span class="content-label">{{
                $t("productInfo.pantsFit")
              }}</span>
              {{ item.styleVersion }}
            </el-row>
            <el-row class="content-line">
              <span class="content-label">{{ $t("productInfo.channel") }}</span>
              {{ item.channelType }}
            </el-row>
            <el-row class="content-line">
              <span class="content-label">{{ $t("productInfo.place") }}</span>
              {{ item.scenario }}
            </el-row>
            <el-row class="content-line">
              <span class="content-label">{{ $t("productInfo.concept") }}</span>
              {{ item.form }}
            </el-row>
            <el-row class="content-line">
              <span class="content-label">{{
                $t("productInfo.trendPoint")
              }}</span>
              {{ item.styleElement }}
            </el-row>
          </div>
        </el-col>
        <el-col v-loading="percentageLoading" v-if="percentage" class="percetage-block" :span="6">
          <el-row class="percetage-line">
            <el-col :span="12">
              <span class="percetage-value">
                {{ percentage.styleRank }} / {{ percentage.totalStyle }}
              </span>
              <br />
              {{ $t("productInfo.overallSalesRanking") }}
            </el-col>
            <el-col :span="12">
              <span class="percetage-value">
                {{ percentage.itemStyleRank }} / {{ percentage.itemTotalStyle }}
              </span>
              <br />
              {{ $t("productInfo.inItemSalesRanking") }}
              ({{ item.t2 }})
            </el-col>
          </el-row>
          <el-row class="percetage-line">
            <el-col :span="12">
              <span class="percetage-value">
                {{ percentageCal(percentage.productStylePercentage) }}
              </span>
              <br />
              {{ $t("productInfo.salesPositionAll") }}
            </el-col>
            <el-col :span="12">
              <span class="percetage-value">
                {{ percentageCal(percentage.productItemPercentage) }}
              </span>
              <br />
              {{ $t("productInfo.salesPositionItem") }}
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <div v-loading="chartLoading" id="left-chart" autoresize />
        </el-col>
        <el-col :span="12">
          <div v-loading="chartLoading" id="right-chart" autoresize />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import { fetchBrand } from "@/api/brand";
import {
  fetchProductInfo,
  fetchProductSalePercentage,
  fetchProductLast12WeekDetail,
  fetchProductBestKey,
} from "@/api/analysis";
import Guide from "@/components/Guide";

export default {
  components: {
    AiBreadcrumb,
    Guide,
  },
  data() {
    return {
      currentPlatform: "tmall",
      platformList: [
        { key: "tmall", value: "TMall" },
        { key: "tiktok", value: "Tiktok" },
      ],
      dataLoading: false,
      chartLoading: false,
      percentageLoading: false,
      brandList: [],
      currentBrandId: null,
      keyword: "",
      item: null,
      percentage: null,
      trend: null,
      chartLeft: null,
      chartRight: null,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.fetchBrandList();
    if (this.$route.query && this.$route.query.q) {
      this.keyword = this.$route.query.q;
      if (this.$route.query.platform) {
        this.currentPlatform = this.$route.query.platform;
      }
      this.fetchData();
    } else {
      this.fetchBestSalesKey();
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
    if (this.leftChart) {
      this.leftChart.dispose();
      this.leftChart = null;
    }
    if (this.rightChart) {
      this.rightChart.dispose();
      this.rightChart = null;
    }
  },
  methods: {
    fetchBrandList() {
      fetchBrand({
        subjects: "Best10,TPS",
        platform: this.currentPlatform,
      }).then((response) => {
        if (response.success) {
          this.brandList = response.result.items || [];
        }
      });
    },
    fetchBestSalesKey() {
      fetchProductBestKey().then((response) => {
        if (response.success) {
          this.keyword = response.result;
          if (this.keyword) {
            this.fetchData();
          }
        }
      });
    },
    fetchData() {
      this.dataLoading = true;
      fetchProductInfo({
        brandId: this.currentBrandId,
        keyword: this.keyword,
        platform: this.currentPlatform,
      })
        .then((response) => {
          if (response.success) {
            this.item = response.result;
            this.fetchPercetage();
            this.fetchTrend();
          }
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    fetchPercetage() {
      if (!this.item) {
        return;
      }
      this.percentageLoading = true;
      fetchProductSalePercentage({
        date: this.item.date,
        brandId: this.item.brand.id,
        item: this.item.t2,
        key: this.item.key,
      })
        .then((response) => {
          if (response.success) {
            this.percentage = response.result;
          }
        })
        .finally(() => {
          this.percentageLoading = false;
        });
    },
    fetchTrend() {
      this.chartLoading = true;
      fetchProductLast12WeekDetail({
        date: this.item.date,
        brandId: this.item.brand.id,
        key: this.item.key,
      })
        .then((response) => {
          if (response.success) {
            this.trend = response.result.items;
            this.drawLeftChart();
            this.drawRightChart();
          }
        })
        .finally(() => {
          this.chartLoading = false;
        });
    },
    drawLeftChart() {
      if (this.leftChart) {
        this.leftChart.dispose();
      }
      let option = {
        grid: {
          top: 40,
          left: 5,
          bottom: 0,
          right: 0,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        legend: {
          data: ["Weekly Qty", "Real Price"],
        },
        xAxis: {
          type: "category",
          data: [],
          axisTick: {
            interval: 0,
          },
          axisLabel: {
            interval: 0,
            rotate: 45,
          },
        },
        yAxis: [
          {
            type: "value",
            name: "Weekly Qty",
            axisLabel: {
              formatter: "{value}",
            },
            max: null,
          },
          {
            type: "value",
            name: "Real Price",
            axisLabel: {
              formatter: "￥ {value}",
            },
            max: null,
          },
        ],
        series: [
          {
            name: "Weekly Qty",
            type: "bar",
            data: [],
          },
          {
            name: "Real Price",
            type: "scatter",
            yAxisIndex: 1,
            data: [],
          },
        ],
      };
      this.trend.forEach((e) => {
        option.xAxis.data.push(e.linkDate);
        option.series[0].data.push(e.weekQty);
        option.series[1].data.push(e.price);
      });
      this.leftChart = echarts.init(document.getElementById("left-chart"));
      this.leftChart.setOption(option, true);
    },
    drawRightChart() {
      if (this.rightChart) {
        this.rightChart.dispose();
      }
      let option = {
        grid: {
          top: 40,
          left: 5,
          bottom: 0,
          right: 0,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        legend: {
          data: ["Sales"],
        },
        xAxis: {
          type: "category",
          data: [],
          axisTick: {
            interval: 0,
          },
          axisLabel: {
            interval: 0,
            rotate: 45,
          },
        },
        yAxis: [
          {
            type: "value",
            name: "Sales",
            axisLabel: {
              formatter: "￥ {value}",
            },
            max: null,
          },
        ],
        series: [
          {
            name: "Sales",
            type: "bar",
            data: [],
          },
        ],
      };
      this.trend.forEach((e) => {
        option.xAxis.data.push(e.linkDate);
        option.series[0].data.push(e.sales);
      });
      this.rightChart = echarts.init(document.getElementById("right-chart"));
      this.rightChart.setOption(option, true);
    },
    handleResize() {
      if (this.leftChart) {
        this.leftChart.resize();
      }
      if (this.rightChart) {
        this.rightChart.resize();
      }
    },
    cutText(ts, sep) {
      if (!ts || ts.length < 1) {
        return "";
      }
      return ts.join(sep).substr(0, 12) + " ...";
    },
    downloadAll() { },
    changePlatform() {
      this.fetchBrandList();
    },
    percentageCal(v) {
      if (v * 1000 > 0) {
        return (v * 100).toFixed(2) + "%";
      } else {
        let rst = (v * 100).toFixed(3);
        if (rst === "0.000") {
          return "--";
        }
        return rst + "%";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.style-search {
  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;
  }

  .toolbar {
    background-color: #fff;
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    text-align: right;

    .search-form {
      float: left;
    }

    .download-btn {
      display: inline-block;
    }
  }

  .data-content {
    overflow: hidden;
    font-size: small;
    margin-bottom: -10px;

    .image-block {
      margin-bottom: 10px;
      height: 248px;
      max-height: 248px;
      display: table-cell;
      vertical-align: middle;

      .image-block-content {
        background-color: #fff;
        width: 100%;
        height: 100%;
      }
    }

    .data-block {
      margin-bottom: 10px;

      .data-block-content {
        background-color: #fff;
        padding-bottom: 5px;

        .content-line {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          color: rgba(0, 0, 0, 0.65);
          padding-left: 10px;

          .content-label {
            color: #a9a9a9;
            margin-right: 5px;
            line-height: 27px;
          }

          /deep/ .el-button--mini {
            padding: 4px 10px;
          }
        }
      }
    }

    .percetage-block {
      background-color: #fff;
      color: #a9a9a9;
      padding: 12px 10px 12px;
      text-align: center;
      white-space: pre-line;
      min-height: 248px;

      .percetage-line {
        margin-top: 20px;
        margin-bottom: 36px;

        .percetage-value {
          font-size: x-large;
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
  }

  #left-chart {
    width: 100%;
    min-height: 248px;
    height: calc(100vh - 100px - 52.5px - 52.5px - 248px - 10px - 10px);
  }

  #right-chart {
    width: 100%;
    min-height: 248px;
    height: calc(100vh - 100px - 52.5px - 52.5px - 248px - 10px - 10px);
  }
}
</style>